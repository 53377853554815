export const calculateTime = (time: number) => {
  if (time < 2) {
    return '1 second';
  } else if (time < 61) {
    return Math.round(time) + ' seconds';
  } else if (time < 91) {
    return '1 minute';
  } else if (time < 10800) {
    return Math.round(time / 60) + ' minutes';
  } else {
    return Math.round(time / 3600) + ' hours';
  }
};
export const calculateTimeShort = (time: number) => {
  if (time < 2) {
    return '1s';
  } else if (time < 61) {
    return Math.round(time) + 's';
  } else if (time < 91) {
    return '1 minute';
  } else if (time < 7200) {
    return Math.round(time / 60) + 'm';
  } else {
    return Math.round(time / 3600) + 'h';
  }
};
export const calculateTimeBefore = (time: number) => {
  if (time < 5) {
    return 'Now';
  } else if (time < 61) {
    return Math.floor(time) + ' seconds ago';
  } else if (time < 91) {
    return '1 minute';
  } else if (time < 7200) {
    return Math.floor(time / 60) + ' minutes ago';
  } else {
    return Math.floor(time / 3600) + ' hours ago';
  }
};
export const calculateTimeUntil = (time: number) => {
  if (time < 1.5) {
    return 'NOW';
  } else if (time < 61) {
    return 'in ' + Math.floor(time) + ' seconds';
  } else if (time < 91) {
    return 'in a minute';
  } else if (time < 7200) {
    return 'in ' + Math.floor(time / 60) + ' minutes';
  } else {
    return 'in ' + Math.floor(time / 3600) + ' hours';
  }
};

export const calculateQueueCount = (count: number) => {
  if (count === 1) {
    return '1 image';
  } else {
    return count + ' images';
  }
};