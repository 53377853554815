import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Link,
  useNavigate,
  useLocation,
} from "react-router-dom";
import StartOuter from "./views/start/StartOuter";
import Learn from "./views/learn/Learn";
import Tokens from "./views/tokens/Tokens";
import Support from "./views/support/Support";
import Privacy from "./views/privacy/Privacy";
import Terms from "./views/terms/Terms";
import Faq from "./views/faq/Faq";

import FirebaseListeners from "./components/listeners/FirebaseListeners";
import "./App.css";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [scrollTop, setScrollTop] = useState(0);
  useEffect(() => {
    const onScroll = (e: any) => {
      setScrollTop(window.pageYOffset);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <div className="App">
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<StartOuter scrollOffset={scrollTop} />} />
        <Route path="/learn" element={<Learn/>} />
        <Route path="/tokens" element={<Tokens/>} />
        <Route path="/support" element={<Support/>} />
        <Route path="/privacy" element={<Privacy/>} />
        <Route path="/terms" element={<Terms/>} />
        <Route path="/faq" element={<Faq/>} />
        <Route path="*" element={<StartOuter scrollOffset={scrollTop} />} />
      </Routes>
      <FirebaseListeners />
    </div>
  );
}

export default App;
