import { useEffect, useState, useRef } from "react";
import { useSpring, animated, config } from '@react-spring/web'
import { Waypoint } from "react-waypoint";

import "../start.css";

const innerHeight = require("ios-inner-height");

function SlidingText(props) {
    
    const [isUp, setToUp] = useState(false);
    const transitionData = useSpring({
        opacity: isUp ? 1 : 0,
        config: config.slow
    });

    return (
        <Waypoint
            onEnter={() => setToUp(true)}
            onLeave={() => setToUp(false)}
            scrollableAncestor={window}
            topOffset={Math.floor(innerHeight() * 0.15)}
            bottomOffset={Math.floor(innerHeight() * 0.2)}
        >
            <animated.div style={transitionData} className="SlidingTextWaypoint">
                {props.textComponent}
            </animated.div>
        </Waypoint>
    );
}

export default SlidingText;
