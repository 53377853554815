import React, { useState, useEffect } from "react";
import { firebaseDB } from "../firebase/firebase";
import { ref, onValue } from "firebase/database";
import { useAppSelector, useAppDispatch } from "../redux/reduxHooks";
import { setStatusState } from "../redux/reduxSlice";

export default function FirebaseListeners(): JSX.Element {
  const dispatch = useAppDispatch();

  useEffect(() => {
    const statusRef = ref(firebaseDB, "/public/status/services");
    return onValue(statusRef, (snapshot) => {
      const tempVal = snapshot.val();
      if (tempVal === null || tempVal === undefined) {
        return;
      }
      const tempArray: any = Object.values(tempVal);
      let servicesOnCount = 0;
      let totalQueueCount = 0;
      let totalQueueTime = 0;
      let isAnyActive = false;

      for (let i = 0; i < tempArray.length; i++) {
        if (
          tempArray[i].manualOff === false &&
          tempArray[i].autoOff === false
        ) {
          servicesOnCount += 1;
          totalQueueCount += tempArray[i].queueCount;
          totalQueueTime += tempArray[i].queueTime * tempArray[i].queueCount;
        }
        if (
          tempArray[i].manualOff === false &&
          tempArray[i].autoOff === false &&
          tempArray[i].queueCount < tempArray[i].queueLimit
        ) {
          isAnyActive = true;
        }
      }

      dispatch(
        setStatusState({
          services: tempArray,
          queueTime: Math.floor(totalQueueTime / servicesOnCount),
          queueCount: Math.floor(totalQueueCount / servicesOnCount),
          isAnyActive: isAnyActive,
        })
      );
    });
  }, []);

  useEffect(() => {
    const statusRef = ref(firebaseDB, "/public/status/generated");
    return onValue(statusRef, (snapshot) => {
      const tempVal = snapshot.val();
      if (tempVal === null || tempVal === undefined) {
        return;
      }
      dispatch(setStatusState({ generated: tempVal }));
    });
  }, []);

  return <></>;
}
