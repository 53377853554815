import {createSlice} from '@reduxjs/toolkit';

export interface ReduxStateIF {
  status: {
    generated: number;
    queueCount: number;
    queueTime: number;
    services: any[];
    isAnyActive: boolean;
  };
}

const initialState: ReduxStateIF = {
  status: {
    generated: 0,
    queueCount: 0,
    queueTime: 0,
    services: [],
    isAnyActive: false,
  },
};

export const reduxSplice = createSlice({
  name: 'redux',
  initialState,

  reducers: {
    setStatusState: (state, action) => {
      console.log({...state.status, ...action.payload})
      state.status = {...state.status, ...action.payload};
    },
  },
});

export const {
  setStatusState,
} = reduxSplice.actions;

export default reduxSplice.reducer;
