import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Lottie from "lottie-react";
import { useNavigate } from "react-router-dom";

import openseaIcon from "../../static/iconOpensea.png";
import etherIcon from "../../static/iconEtherscan.png";
import twitterIcon from "../../static/iconTwitter.png";
import mailIcon from "../../static/iconInsta.png";

import animation from "../../components/animations/heartbreaker.json";

import "./footer.css";

function BottomSocial() {
  const navigate = useNavigate();
  return (
    <div className="BottomSocial">
      <div className="BottomSocialTop">
        <Button
          className="BottomSocialOuterButton"
          sx={{
            margin: "15px",
            borderRadius: "30px",
            backgroundColor: "rgba(0,0,0,0.5)",
            borderColor: "rgba(250,250,250,0.5)",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.4)",
            },
          }}
          size="small"
          variant="outlined"
          color="inherit"
          startIcon={
            <img
              className="BottomSocialOuterButtonIcon"
              src={twitterIcon}
              alt="Icon"
            />
          }
        >
          <p className="BottomSocialOuterButtonText">Twitter</p>
        </Button>
        <Button
          className="BottomSocialOuterButton"
          sx={{
            margin: "15px",
            borderRadius: "30px",
            backgroundColor: "rgba(0,0,0,0.5)",
            borderColor: "rgba(250,250,250,0.5)",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.4)",
            },
          }}
          size="small"
          variant="outlined"
          color="inherit"
          startIcon={
            <img
              className="BottomSocialOuterButtonIcon"
              src={mailIcon}
              alt="Icon"
            />
          }
        >
          <p className="BottomSocialOuterButtonText">Instagram</p>
        </Button>
        <Button
          className="BottomSocialOuterButton"
          sx={{
            margin: "15px",
            borderRadius: "30px",
            backgroundColor: "rgba(0,0,0,0.5)",
            borderColor: "rgba(250,250,250,0.5)",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.4)",
            },
          }}
          size="small"
          variant="outlined"
          color="inherit"
          startIcon={
            <img
              className="BottomSocialOuterButtonIcon"
              src={openseaIcon}
              alt="Icon"
            />
          }
        >
          <p className="BottomSocialOuterButtonText">Opensea</p>
        </Button>
        <Button
          className="BottomSocialOuterButton"
          sx={{
            margin: "15px",
            borderRadius: "30px",
            backgroundColor: "rgba(0,0,0,0.5)",
            borderColor: "rgba(250,250,250,0.5)",
            "&:hover": {
              backgroundColor: "rgba(0,0,0,0.4)",
            },
          }}
          size="small"
          variant="outlined"
          color="inherit"
          startIcon={
            <img
              className="BottomSocialOuterButtonIcon"
              src={etherIcon}
              alt="Icon"
            />
          }
        >
          <p className="BottomSocialOuterButtonText">Etherscan</p>
        </Button>
      </div>
      <Lottie
        className="BottomSocialAnimation"
        loop={true}
        animationData={animation}
      />
      <div className="BottomSocialLinks">
        <div className="BottomSocialLinksInner">
            <p className="BottomSocialLink" onClick={() => navigate('/terms')}>Terms of Service</p>
            <p className="BottomSocialLink" onClick={() => navigate('/privacy')}>Privacy Policy</p>
            <p className="BottomSocialLink" onClick={() => navigate('/learn')}>Learn More</p>
            <p className="BottomSocialLink" onClick={() => navigate('/tokens')}>Tokens</p>
            <p className="BottomSocialLink" onClick={() => navigate('/faq')}>FAQ</p>
        </div>
        <div className="BottomSocialLinksInner">
            <b className="BottomSocialLinkAlt">© 2023 Portraiture.ai</b>
        </div>
      </div>
    </div>
  );
}

export default BottomSocial;
