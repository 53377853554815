import React from "react";
import type { PropsWithChildren } from "react";
import './ui.css';

type tsProps = PropsWithChildren<{
  color: string;
}>;

function PortraitureIcon({color}: tsProps) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1300 1134.65"
      className="PortraitureIcon"
    >
      <path
        fill={color}
        d="M351 1555.5L986 436.69a7.91 7.91 0 0113.73-.07l89.45 154.14a7.94 7.94 0 01.06 7.84l-441.61 790a7.93 7.93 0 006.87 11.79l165.77.84a8 8 0 006.9-4l217-375.9a7.92 7.92 0 0113.65-.12l87.4 145.82a7.93 7.93 0 01.07 8l-224.35 388.34a7.9 7.9 0 01-6.85 4H357.93a7.92 7.92 0 01-6.93-11.87z"
        transform="translate(-350 -432.68)"
      ></path>
      <path
        fill={color}
        d="M1096.11 939.9l87.4 145.85a7.91 7.91 0 0013.63-.09l33.77-58.15a7.92 7.92 0 00-.06-8.06l-87.62-145.76a7.92 7.92 0 00-13.64.12L1096 931.87a7.91 7.91 0 00.11 8.03zM1642.07 1567.32h-165.49a7.93 7.93 0 01-6.8-3.84l-194-323.83a7.91 7.91 0 010-8.08l86.4-146.89a7.91 7.91 0 0113.67 0L1649 1555.39a7.92 7.92 0 01-6.93 11.93z"
        transform="translate(-350 -432.68)"
      ></path>
    </svg>
  );
}

export default PortraitureIcon;
