import React, { useState, useEffect, useRef } from "react";
import type { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import PortraitureIcon from "../../components/ui/PortraitureIcon";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

import "./terms.css";
import "../../App.css";

const listData = [
  {
    title: "Eligibility",
    text: "You must be at least 13 years old to use the Services. By agreeing to these Terms, you represent and warrant that you are at least 13 years old and have the legal capacity to enter into a binding contract.",
    list: [],
  },
  {
    title: "License",
    text: "Subject to your compliance with these Terms, we grant you a limited, non-exclusive, non-transferable, and revocable license to access and use the Services for your personal, non-commercial use.",
    list: [],
  },
  {
    title: "Restrictions",
    text: "You agree not to use the Services in any way that violates any applicable law or regulation, infringes on any third partys rights, or is otherwise abusive, defamatory, or harmful. You also agree not to interfere with, disrupt, or attempt to gain unauthorized access to the Services or their related systems or networks. You may not use the Services to engage in any activity that could be harmful to us or our users, including, but not limited to, the following:",
    list: [
      "Using the Services to upload, transmit, or otherwise distribute any content that is unlawful, harmful, threatening, abusive, harassing, tortious, defamatory, vulgar, obscene, libelous, invasive of anothers privacy, hateful, or racially, ethnically, or otherwise objectionable.",
      "Using the Services to upload, transmit, or otherwise distribute any content that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements).",
      "Using the Services to upload, transmit, or otherwise distribute any content that infringes any patent, trademark, trade secret, copyright, right of publicity, or other proprietary right of any party.",
      "Using the Services to upload, transmit, or otherwise distribute any content that you know is not correct and current.",
      "Using the Services to upload, transmit, or otherwise distribute any content that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment.",
      'Using the Services to upload, transmit, or otherwise distribute any content that contains unsolicited or unauthorized advertising, promotional materials, "junk mail," "spam," "chain letters," "pyramid schemes," or any other form of solicitation.',
      "Using the Services to upload, transmit, or otherwise distribute any content that contains personal information about another person without that persons consent.",
      "Using the Services to upload, transmit, or otherwise distribute any content that impersonates any person or entity, including any of our employees or representatives.",
      "Using the Services to upload, transmit, or otherwise distribute any content that is false, misleading, or deceptive.",
      "Using the Services to upload, transmit, or otherwise distribute any content that is intended to or does harass, abuse, or harm another person.",
      "Using the Services to upload, transmit, or otherwise distribute any content that is intended to or does threaten, defame, or defraud any person or entity.",
      "Using the Services to upload, transmit, or otherwise distribute any content that is intended to or does violate any applicable law or regulation.",
      "Using the Services to upload, transmit, or otherwise distribute any content that is intended to or does violate any right of any third party, including any right of privacy or intellectual property rights.",
      "Using the Services to upload, transmit, or otherwise distribute any content that is intended to or does violate any contractual or fiduciary relationship.",
      "Using the Services to upload, transmit, or otherwise distribute any content that is intended to or does violate any of our policies, including these Terms.",
    ],
  },
  {
    title: "User Content",
    text: 'You are solely responsible for any content you submit or generate through the Services, including but not limited to images and text ("User Content"). By submitting or generating User Content, you grant us a worldwide, non-exclusive, royalty-free, sublicensable, and transferable license to use, reproduce, modify, and distribute your User Content for the purpose of providing and improving the Services.',
    list: [],
  },
  {
    title: "Intellectual Property",
    text: "All intellectual property rights in the Services, including but not limited to copyrights, trademarks, and trade secrets, are owned by us or our licensors. Except for the limited license granted in these Terms, we reserve all rights in and to the Services. You may not use any of our trademarks, service marks, logos, domain names, or other distinctive brand features without our prior written consent.",
    list: [],
  },
  {
    title: "In-App Purchases",
    text: "The Services may offer in-app purchases for additional features or content. All in-app purchases are subject to the terms and conditions of the applicable app store and are non-refundable. You may be required to provide additional information to complete your purchase, including, but not limited to, your name, address, and payment information. You agree to provide accurate and complete information and to promptly update your information as necessary. You are responsible for all purchases made through your account, including purchases made by any person to whom you provide your account information. You may be required to provide additional information to complete your purchase, including, but not limited to, your name, address, and payment information. You agree to provide accurate and complete information and to promptly update your information as necessary. You are responsible for all purchases made through your account, including purchases made by any person to whom you provide your account information.",
    list: [],
  },
  {
    title: "Termination",
    text: "We may terminate or suspend your access to the Services at any time and for any reason, including but not limited to a violation of these Terms. Upon termination, your right to use the Services will immediately cease.",
    list: [],
  },
  {
    title: "Disclaimer",
    text: 'The Services are provided "as is" and without any warranty, express or implied. We disclaim all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.',
    list: [],
  },
  {
    title: "Limitation of Liability",
    text: "In no event shall we be liable for any direct, indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your access to or use of the Services, even if we have been advised of the possibility of such damages. Our aggregate liability arising out of or in connection with these Terms or your access to or use of the Services will not exceed the amount you paid us in the twelve (12) months prior to the event giving rise to the liability.",
    list: [],
  },
  {
    title: "Governing Law and Dispute Resolution",
    text: "These Terms shall be governed by and construed in accordance with the laws of Sweden. Any disputes arising out of or in connection with these Terms shall be resolved through [the appropriate dispute resolution mechanism, such as mediation, arbitration, or litigation in a specific jurisdiction].",
    list: [],
  },
  {
    title: "Changes to Terms",
    text: "We may modify these Terms at any time by posting the revised Terms on our website and/or within the app. Your continued use of the Services after any such modification constitutes your acceptance of the updated Terms.",
    list: [],
  },
  {
    title: "Contact Information",
    text: "If you have any questions or concerns about these Terms or the Services, please contact us at info@portraiture.ai.",
    list: [],
  },
];

function Terms() {
  const navigate = useNavigate();
  const linkClick = () => {
    navigate("/");
  };
  return (
    <div className="TemplateOuter">
      <div className="TemplateHeader">
        <div className="TemplateHeaderLink" onClick={linkClick}>
          <ArrowBackIosNewIcon
            sx={{ fontSize: "200%", marginBottom: "-2px" }}
          />
        </div>
        <p className="TemplateHeaderTitle">TERMS</p>
        <div className="TemplateHeaderLink" onClick={linkClick}>
          <PortraitureIcon color="#5c9ead" />
        </div>
      </div>
      <div className="Faq">
        {listData.map((item, index) => (
          <div className="FaqItemOuter" key={index}>
            <div className="FaqItem">
              <p className="FaqItemTitle">{item.title}</p>
              <p className="FaqItemSubtitle">{item.text}</p>
              {item.list.length > 0
                ? item.list.map((listItem, index2) => (
                    <p className="TermsListText" key={index2}>
                    - {listItem}
                    </p>
                  ))
                : null}
            </div>
            <div className="FaqDivider"></div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Terms;
