import React, { useState, useEffect, useRef } from "react";
import type { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import PortraitureIcon from "../../components/ui/PortraitureIcon";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CircularProgress from "@mui/material/CircularProgress";

import "./support.css";
import "../../App.css";

type tsProps = PropsWithChildren<{
  scrollOffset: number;
}>;

function Support() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [sent, setSent] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [extra, setExtra] = useState("Fu36MgtQpR");

  const navigate = useNavigate();
  const linkClick = () => {
    navigate("/");
  };

  const sendEmail = async () => {
    if (loading) {
      return;
    }
    if (name === "" || email === "" || message === "" || subject === "") {
      setError("All fields are required before sending.");
      return;
    }
    if (message.length < 30 || message.length > 2000) {
      setError("Message must be between 30 - 2000 characters long.");
      return;
    }
    if (extra !== "Fu36MgtQpR") {
      setError("Something went wrong, please try again later.");
      return;
    }
    if (sent) {
      return;
    }
    try {
      setLoading(true);
      const response = await fetch("https://api.portraiture.ai/support/web", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name: name,
          email: email,
          subject: subject,
          message: message,
          extra: extra,
        }),
      });
      if (response.status === 200) {
        setSent(true);
        setError("Message sent successfully! We will get back to you soon.");
        setLoading(false);
      } else {
        setError("Something went wrong, please try again later.");
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setError("Something went wrong, please try again later.");
      setLoading(false);
    }
  };

  return (
    <div className="TemplateOuter">
      <div className="TemplateHeader">
        <div className="TemplateHeaderLink" onClick={linkClick}>
          <ArrowBackIosNewIcon
            sx={{ fontSize: "200%", marginBottom: "-2px" }}
          />
        </div>
        <p className="TemplateHeaderTitle">SUPPORT</p>
        <div className="TemplateHeaderLink" onClick={linkClick}>
          <PortraitureIcon color="#5c9ead" />
        </div>
      </div>
      <div className="Tokens">
        <p className="TokensTitle">
          {error !== ""
            ? error
            : "Send us a message and we will get back to you as soon as possible."}
        </p>
        <div className="SupportInner">
          <input
            className="SupportInput"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <input
            className="SupportInput"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="SupportInput"
            placeholder="Subject"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
          />
          <textarea
            className="SupportInput"
            placeholder="Message"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            rows={5}
          />
          <div className="SupportButton" onClick={sendEmail}>
            {loading ? (
              <CircularProgress size={22} color="inherit" />
            ) : sent ? (
              "MESSAGE SENT!"
            ) : (
              "SEND"
            )}
          </div>
          <input
            className="SupportInputHide"
            placeholder="Extra"
            onChange={(e) => setExtra(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
}

export default Support;
