import React, { useState, useEffect, useRef } from "react";
import type { PropsWithChildren } from "react";
import Lottie from "lottie-react";
import { useSpring, animated, config } from "@react-spring/web";

import SlidingImage from "./components/SlidingImage";
import SlidingText from "./components/SlidingText";

import background from "../../static/background1.png";
import "./start.css";

const randomImages = [
  require("../../static/img/0.jpeg"),
  require("../../static/img/1.jpeg"),
  require("../../static/img/2.jpeg"),
  require("../../static/img/3.jpeg"),
  require("../../static/img/4.jpeg"),
  require("../../static/img/5.jpeg"),
  require("../../static/img/6.jpeg"),
  require("../../static/img/7.jpeg"),
  require("../../static/img/8.jpeg"),
  require("../../static/img/9.jpeg"),
  require("../../static/img/10.jpeg"),
  require("../../static/img/11.jpeg"),
  require("../../static/img/12.jpeg"),
  require("../../static/img/13.jpeg"),
  require("../../static/img/14.jpeg"),
  require("../../static/img/15.jpeg"),
  require("../../static/img/16.jpeg"),
];

type tsProps = PropsWithChildren<{
  scrollOffset: number;
}>;

function StartSub({ scrollOffset }: tsProps) {
  const [height, setHeight] = useState(0);
  const ref = useRef(null);
  const [springImages, setSpringImages] = useState([] as any[]);

  const initImages = () => {
    let tempArr: Array<number> = [];
    for (let i = 0; i < 8; i++) {
      let randomNumber = Math.floor(Math.random() * 16);
      while (tempArr.includes(randomNumber)) {
        randomNumber = Math.floor(Math.random() * 16);
      }
      tempArr.push(randomNumber);
    }
    setSpringImages(tempArr);
  };

  const changeImage = () => {
    let tempArr = [...springImages];
    let randomArrNumber = Math.floor(Math.random() * 8);
    let randomNumber = Math.floor(Math.random() * 16);
    while (tempArr.includes(randomNumber)) {
      randomNumber = Math.floor(Math.random() * 16);
    }

    tempArr.splice(randomArrNumber, 1, randomNumber);
    setSpringImages(tempArr);
  };

  useEffect(() => {
    if (springImages.length <= 0) {
      initImages();
    }
  }, [springImages]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (springImages.length > 0) {
        changeImage();
      }
    }, 4000);
    return () => clearInterval(interval);
  }, [springImages]);

  return (
    <div className="StartNewSub">
      <div className="StartNewSubBack">
        <img src={background} alt="Logo" className="StartNewSubBackImg" />
        <img src={background} alt="Logo" className="StartNewSubBackImg" />
      </div>
      {springImages.length > 0 ? (
        <div className="StartNewSubFrontOuter">
          <div className="StartNewSubFrontInner">
            <div className="Square33Div" style={{ backgroundColor: "#1D3557" }}>
              <SlidingText
                textComponent={
                  <p className="StartNewTitle">
                    UNLEASH YOUR CREATIVITY WITH AI-POWERED ART!
                  </p>
                }
              />
            </div>
            <div className="Square33Div">
              <img
                className="StartImageLarge"
                src={require("../../static/img/model1.jpg")}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square33Div">
              <img
                className="StartImageLarge"
                src={require("../../static/img/prompt2.jpg")}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square33Div" style={{ backgroundColor: "#5C9EAD" }}>
              <SlidingText
                textComponent={
                  <p className="StartNewTitle">
                    EASILY CREATE ART WITHOUT ANY PRIOR KNOWLEDGE
                  </p>
                }
              />
            </div>
            <div
              className="Landscape32Div"
              style={{ backgroundColor: "#457B9D" }}
            >
              <p className="StartNewInfoTitle">WELCOME TO PORTRAITURE.AI!</p>
              <p className="StartNewInfoText">
                Discover the power of artificial intelligence in creating unique
                and personalized art. Transform any image or text into stunning,
                one-of-a-kind artwork.
              </p>
            </div>
            <div className="Square33Div">
              <img
                className="StartImageLarge"
                src={require("../../static/img/prompt5.jpg")}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square33Div">
              <img
                className="StartImageLarge"
                src={require("../../static/img/prompt4.jpg")}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square33DivHide">
              <img
                className="StartImageLarge"
                src={require("../../static/img/prompt3.jpg")}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square33DivHide">
              <img
                className="StartImageLarge"
                src={require("../../static/img/seed3.jpg")}
                alt="Portraiture Logo"
              />
            </div>
            <div
              className="Landscape32Div"
              style={{ backgroundColor: "#5C9EAD" }}
            >
              <p className="StartNewInfoTitle">WHAT IS AI ART?</p>
              <p className="StartNewInfoText">
                AI art is generated with the help of artificial intelligence. It
                combines the creativity of an artist and the power of AI
                algorithms to produce amazing work.
              </p>
            </div>
            <div className="Square1Div">
              <img
                className="StartImageLarge"
                src={randomImages[springImages[0]]}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square1Div">
              <img
                className="StartImageLarge"
                src={randomImages[springImages[1]]}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square1Div">
              <img
                className="StartImageLarge"
                src={randomImages[springImages[2]]}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square1Div">
              <img
                className="StartImageLarge"
                src={randomImages[springImages[3]]}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square1Div">
              <img
                className="StartImageLarge"
                src={randomImages[springImages[4]]}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square1Div">
              <img
                className="StartImageLarge"
                src={randomImages[springImages[5]]}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square1Div">
              <img
                className="StartImageLarge"
                src={randomImages[springImages[6]]}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square1Div">
              <img
                className="StartImageLarge"
                src={randomImages[springImages[7]]}
                alt="Portraiture Logo"
              />
            </div>
            <div
              className="Landscape32Div"
              style={{ backgroundColor: "#457B9D" }}
            >
              <p className="StartNewInfoTitle">CREATE YOUR MASTERPIECE</p>
              <p className="StartNewInfoText">
                Our AI Art generator turns any image or text prompt into
                beautiful art. Adjust settings to create a truly personalized
                piece, or input text and watch our AI bring it to life.
              </p>
            </div>
            <div className="Square33DivHideInvert">
              <img
                className="StartImageLarge"
                src={require("../../static/img/image3.jpg")}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square33DivHideInvert">
              <img
                className="StartImageLarge"
                src={require("../../static/img/model2.jpg")}
                alt="Portraiture Logo"
              />
            </div>
            <div
              className="Landscape32Div"
              style={{ backgroundColor: "#5C9EAD" }}
            >
              <p className="StartNewInfoTitle">YOUR PRIVATE GALLERY</p>
              <p className="StartNewInfoText">
                All creations are saved in your private gallery, ready to be
                admired or shared. Download your art to share on social
                platforms and impress your friends.
              </p>
            </div>
            <div className="Square33DivHide">
              <img
                className="StartImageLarge"
                src={require("../../static/img/image3.jpg")}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square33DivHideInvert">
              <img
                className="StartImageLarge"
                src={require("../../static/img/prompt3.jpg")}
                alt="Portraiture Logo"
              />
            </div>
            <div className="Square33DivHideInvert">
              <img
                className="StartImageLarge"
                src={require("../../static/img/seed3.jpg")}
                alt="Portraiture Logo"
              />
            </div>
            <div
              className="Landscape32Div"
              style={{ backgroundColor: "#1D3557" }}
            >
              <p className="StartNewInfoTitle">LET'S GET STARTED!</p>
              <p className="StartNewInfoText">
                Enjoy Portraiture.ai and start creating your next masterpiece
                today!
              </p>
            </div>
            <div className="Square33DivHide">
              <img
                className="StartImageLarge"
                src={require("../../static/img/model2.jpg")}
                alt="Portraiture Logo"
              />
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default StartSub;
/*<div className="StartNewDivider"></div>*/
