import React, { useState, useEffect, useRef } from "react";
import type { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import PortraitureIcon from "../../components/ui/PortraitureIcon";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AppleIcon from "@mui/icons-material/Apple";
import GoogleIcon from "@mui/icons-material/Google";
import LoopIcon from "@mui/icons-material/Loop";
import TokenIcon from '@mui/icons-material/Token';
import CurrencyBitcoinIcon from '@mui/icons-material/CurrencyBitcoin';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import RedeemIcon from '@mui/icons-material/Redeem';
import AdsClickIcon from '@mui/icons-material/AdsClick';

import "./tokens.css";
import "../../App.css";

type tsProps = PropsWithChildren<{
  scrollOffset: number;
}>;

function Tokens() {
  const navigate = useNavigate();
  const linkClick = () => {
    navigate("/");
  };
  return (
    <div className="TemplateOuter">
      <div className="TemplateHeader">
        <div className="TemplateHeaderLink" onClick={linkClick}>
          <ArrowBackIosNewIcon
            sx={{ fontSize: "200%", marginBottom: "-2px" }}
          />
        </div>
        <p className="TemplateHeaderTitle">TOKENS</p>
        <div className="TemplateHeaderLink" onClick={linkClick}>
          <PortraitureIcon color="#5c9ead" />
        </div>
      </div>
      <div className="Tokens">
        <p className="TokensTitle">
          You can acquire tokens from the following sources:
        </p>
        <div className="TokenItemOuter">
          <div className="LearnDivider"></div>
          <div className="TokenItem">
            <div className="TokenItemHeader">
              <p className="LearnItemTitle">1. Apple App Store</p>
              <AppleIcon sx={{ opacity: 0.8 }} />
            </div>
            <p className="LearnItemSubtitle">
              Easiest way to get tokens if you using the iOS-app is to buy them
              from the Apple App Store.
            </p>
            <div className="TokenItemButton">Go To App Store</div>
          </div>
        </div>
        <div className="TokenItemOuter">
          <div className="LearnDivider"></div>
          <div className="TokenItem">
            <div className="TokenItemHeader">
              <p className="LearnItemTitle">2. Google Play Store</p>
              <GoogleIcon sx={{ opacity: 0.8 }} />
            </div>
            <p className="LearnItemSubtitle">
              Easiest way to get tokens if you using the Android-app is to buy
              them from the Google Play Store.
            </p>
            <div className="TokenItemButton">Go To Play Store</div>
          </div>
        </div>
        <div className="TokenItemOuter">
          <div className="LearnDivider"></div>
          <div className="TokenItem">
            <div className="TokenItemHeader">
              <p className="LearnItemTitle">3. Referral Codes</p>
              <LoopIcon sx={{ opacity: 0.8 }} />
            </div>
            <p className="LearnItemSubtitle">
              You can get tokens by using your friends referral code when
              register. Share your own code to gain even more. You both get 30
              tokens each!
            </p>
          </div>
        </div>
        <div className="TokenItemOuter">
          <div className="LearnDivider"></div>
          <div className="TokenItem">
            <div className="TokenItemHeader">
              <p className="LearnItemTitle">4. Watching Ads</p>
              <AdsClickIcon sx={{ opacity: 0.8 }} />
            </div>
            <p className="LearnItemSubtitle">
              Watch ads and get rewarded with tokens. Watching 2 ads gives you 1
              token. You can watch up to 30 ads per hour.
            </p>
          </div>
        </div>
        <div className="TokenItemOuter">
          <div className="LearnDivider"></div>
          <div className="TokenItem">
            <div className="TokenItemHeader">
              <p className="LearnItemTitle">5. Voucher Codes</p>
              <RedeemIcon sx={{ opacity: 0.8 }} />
            </div>
            <p className="LearnItemSubtitle">
              Participate in our contests, giveaways and more on social media to
              get voucher codes. Redeem these codes to get tokens!
            </p>
          </div>
        </div>
        <div className="TokenItemOuter">
          <div className="LearnDivider"></div>
          <div className="TokenItem">
            <div className="TokenItemHeader">
              <p className="LearnItemTitle">6. Register</p>
              <PersonAddIcon sx={{ opacity: 0.8 }} />
            </div>
            <p className="LearnItemSubtitle">
              All new users get tokens for free! Register now to get your free
              tokens!
            </p>
          </div>
        </div>
        <div className="TokenItemOuter">
          <div className="LearnDivider"></div>
          <div className="TokenItem">
            <div className="TokenItemHeader">
              <p className="LearnItemTitle">7. Crypto</p>
              <CurrencyBitcoinIcon sx={{ opacity: 0.8 }} />
            </div>
            <p className="LearnItemSubtitle">
              On our website you can buy tokens with crypto currencies. You can
              pay with Bitcoin, Ethereum, Polygon and many more!
            </p>
          </div>
        </div>
        <div className="TokenItemOuter">
          <div className="LearnDivider"></div>
          <div className="TokenItem">
            <div className="TokenItemHeader">
              <p className="LearnItemTitle">8. NFT Collection</p>
              <TokenIcon sx={{ opacity: 0.8 }} />
            </div>
            <p className="LearnItemSubtitle">
              All holders of our NFT collection get free tokens every month and
              many more benefits! You can mint our NFTs on our website.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tokens;
