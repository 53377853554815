import React, { useState, useEffect, useRef } from "react";
import type {PropsWithChildren} from 'react';
import Lottie from "lottie-react";
import { useSpring, animated, config } from "@react-spring/web";

import animation from "../../components/animations/logoMain.json";

import logoOverlay from "../../static/mainOverlay.jpg";
import "./start.css";

const innerHeight = require("ios-inner-height");

type tsProps = PropsWithChildren<{
  scrollOffset: number;
}>;

function Start({scrollOffset}: tsProps) {
  const [height, setHeight] = useState(0);
  const ref: any = useRef(null);

  const heightTransition = useSpring({
    to: { height: 0 },
    from: { height: innerHeight() },
    delay: 800,
    config: config.slow,
  });

  const titleTransition = useSpring({
    to: { y: 0 },
    from: { y: 100 },
    delay: 900,
    config: config.slow,
  });

  const logoOpacity = useSpring({
    opacity: scrollOffset < 100 ? 1 : 0,
    config: config.slow,
  });


  return (
    <div className="StartNew" style={{ height: innerHeight() }}>
      <div className="StartNewInner">
        <animated.div style={logoOpacity} className="LogoNewAnimation">
          <Lottie
            loop={true}
            animationData={animation}
            className="LogoNewAnimationInner"
          />
        </animated.div>

        <animated.div
          ref={ref}
          className="StartNewInnerTextOuter"
        >
          <div className="StartNewInnerTextDiv">
            <animated.p
              className="StartNewInnerBarcode"
              style={titleTransition}
            >
              PORTRAITURE.Ai
            </animated.p>
          </div>
          <div className="StartNewInnerTextDiv">
            <animated.p className="StartNewInnerTitle" style={titleTransition}>
              PORTRAITURE.Ai
            </animated.p>
          </div>
          <div className="StartNewInnerTextDiv">
            <animated.p
              className="StartNewInnerBarcode"
              style={titleTransition}
            >
              PORTRAITURE.Ai
            </animated.p>
          </div>
          <div className="StartNewInnerTextDiv">
            <animated.p className="StartNewInnerText" style={titleTransition}>
              Ai ART GENERATOR
            </animated.p>
          </div>
          <div className="StartNewInnerTextDiv">
            <animated.p
              className="StartNewInnerTextSmall"
              style={titleTransition}
            >
              CREATE STUNNING ART FROM YOUR PHOTOS
            </animated.p>
          </div>
        </animated.div>
      </div>
      <animated.div className="StartNewOverlay" style={heightTransition}>
        <div className="StartNewOverlayInner" style={{ height: innerHeight() }}>
          <img src={logoOverlay} alt="Logo" className="LogoNewAnimation" />
        </div>
      </animated.div>
    </div>
  );
}

export default Start;
