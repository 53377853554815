export const firebaseConfig = {
  apiKey: "AIzaSyAiB1YV6JsNFzq2yGL7PbJUPgsdokvgNsM",
  authDomain: "portraiture-app.firebaseapp.com",
  databaseURL: "https://portraiture-app-default-rtdb.firebaseio.com",
  projectId: "portraiture-app",
  storageBucket: "portraiture-app.appspot.com",
  messagingSenderId: "826677504324",
  appId: "1:826677504324:web:6b33207ba2e30a431b8f11",
  measurementId: "G-SS1MGFF31P",
};
