import React, { useState, useEffect, useRef } from "react";
import type { PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import TokenRoundedIcon from "@mui/icons-material/TokenRounded";
import SchoolRoundedIcon from "@mui/icons-material/SchoolRounded";
import TollIcon from "@mui/icons-material/Toll";

import Lottie from "lottie-react";
import animation from "../../components/animations/logoMain.json";

import "./startNew.css";

type tsProps = PropsWithChildren<{
  scrollOffset: number;
}>;

function AvailableLinks() {
    const navigate = useNavigate();
  return (
    <div className="AvailableLinks">
      <div className="AvailableLinksTitleDiv">
        <p className="AvailableLinksTitle">
          You can download the app from the following links:
        </p>
      </div>
      <div className="AvailableLinksDiv">
        <img
          className="AvailableLinksImage"
          src={require("../../static/appstore.png")}
          alt="Portraiture Logo"
        />
        <p className="AvailableLinksText">JUNE 2023</p>
      </div>
      <div className="AvailableLinksDiv">
        <img
          className="AvailableLinksImage"
          src={require("../../static/playstore.png")}
          alt="Portraiture Logo"
        />
        <p className="AvailableLinksText">JULY 2023</p>
      </div>
      <div className="AvailableLinksTitleDiv">
        <p className="AvailableLinksTitle">Or you can use the web app:</p>
      </div>
      <div className="AvailableLinksDiv">
        <Lottie
          loop={true}
          animationData={animation}
          className="AvailableLinksLogo"
        />
        <p className="AvailableLinksText">AVAILABLE JUNE 2023</p>
      </div>
      <div className="AvailableLinksInner">
        <div className="AvailableLinksInnerDiv">
          <div className="AvailableLinksInnerDivTop"></div>
          <div className="AvailableLinksInnerTitleOuter">
            <div className="AvailableLinksInnerTitleInner">
              <p className="AvailableLinksInnerTitle">LEARN</p>
              <SchoolRoundedIcon
                sx={{
                  fontSize: "300%",
                  backgroundColor: "#222",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  borderRadius: "35%",
                  "@media (max-width: 500px)": {
                    fontSize: "220%",
                    paddingTop: "7px",
                    paddingBottom: "7px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    borderRadius: "30%",
                  },
                  "@media (max-width: 350px)": {
                    fontSize: "200%",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    borderRadius: "35%",
                  },
                }}
              />
            </div>
          </div>
          <div className="AvailableLinksInnerDivText">
            <p className="AvailableLinksInnerTextUpper">
              Become a master of the app by learning how to use it to its full
              potential.
            </p>
            <p className="AvailableLinksInnerText">
              With the right prompts and settings, you can create stunning art
              from your photos. Discover our many tips and tricks to create the
              best art.
            </p>
            <div className="AvailableLinksInnerDivButton" onClick={()=> navigate('/learn')}>
              <p className="AvailableLinksInnerDivButtonText">DISCOVER</p>
            </div>
          </div>
        </div>
        <div className="AvailableLinksInnerDiv">
          <div className="AvailableLinksInnerDivTop"></div>
          <div className="AvailableLinksInnerTitleOuter">
            <div className="AvailableLinksInnerTitleInner">
              <p className="AvailableLinksInnerTitle">TOKENS</p>
              <TokenRoundedIcon
                sx={{
                  fontSize: "300%",
                  backgroundColor: "#222",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  paddingLeft: "20px",
                  paddingRight: "20px",
                  borderRadius: "35%",
                  "@media (max-width: 500px)": {
                    fontSize: "220%",
                    paddingTop: "7px",
                    paddingBottom: "7px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                    borderRadius: "30%",
                  },
                  "@media (max-width: 350px)": {
                    fontSize: "200%",
                    paddingTop: "5px",
                    paddingBottom: "5px",
                    paddingLeft: "12px",
                    paddingRight: "12px",
                    borderRadius: "35%",
                  },
                }}
              />
            </div>
          </div>
          <div className="AvailableLinksInnerDivText">
            <p className="AvailableLinksInnerTextUpper">
              There are multiple sources to acquire tokens for the Ai
              Proccessing in the app.
            </p>
            <p className="AvailableLinksInnerText">
              You can buy tokens in the app, earn tokens by watching ads, by
              sharing the app with your friends and family plus from multiple other
              sources.
            </p>
            <div className="AvailableLinksInnerDivButton">
              <p className="AvailableLinksInnerDivButtonText" onClick={()=> navigate('/tokens')}>DISCOVER</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AvailableLinks;
