import React, { useState, useEffect, useRef } from "react";
import { useAppSelector } from "../../components/redux/reduxHooks";

import "./footer.css";

import { calculateTimeShort } from "../../components/utils/parsers";

let isLoading = false;
function BottomStats() {
  const [serverCount, setServerCount] = useState(0);
  const [modelCount, setModelCount] = useState(0);

 const statusState = useAppSelector(state => state.redux.status);

  const calculateServers = () => {
    let tempServers = 0;
    for (let i = 0; i < statusState.services.length; i++) {
      if (
        statusState.services[i].autoOff === false &&
        statusState.services[i].manualOff === false
      ) {
        if (typeof statusState.services[i].gpu === "object") {
          tempServers += Object.values(statusState.services[i].gpu).length;
        } else {
          tempServers++;
        }
      }
    }
    setServerCount(tempServers);
  };

  const calculateModels = () => {
    let tempModels = 0;
    for (let i = 0; i < statusState.services.length; i++) {
      if (
        statusState.services[i].autoOff === false &&
        statusState.services[i].manualOff === false
      ) {
        tempModels++;
      }
    }
    setModelCount(tempModels);
  };

  useEffect(() => {
    calculateServers();
  }, [statusState]);

  useEffect(() => {
    calculateModels();
  }, [statusState]);
  return statusState !== null ? (
    <div className="BottomStatsOuter">
      <p className="BottomStatsTitle">{""} PORTRAITURE.AI STATS</p>
      <div className="StartNewSubFrontInner">
        <div className="BottomStatsInner">
          <div className="BottomStatsDiv">
            <p className="BottomStatsDivTitle">{statusState.generated}</p>
            <p className="BottomStatsDivText">IMAGES GENERATED</p>
          </div>
          <div className="BottomStatsDiv">
            <p className="BottomStatsDivTitle">
              {calculateTimeShort(statusState.queueTime + 6)}
            </p>
            <p className="BottomStatsDivText">AVERAGE QUEUE</p>
          </div>
          <div className="BottomStatsDiv">
            <p className="BottomStatsDivTitle">{serverCount}</p>
            <p className="BottomStatsDivText">ACTIVE SERVICES</p>
          </div>
          <div className="BottomStatsDiv">
            <p className="BottomStatsDivTitle">{modelCount}</p>
            <p className="BottomStatsDivText">ACTIVE MODELS</p>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default BottomStats;
