import { useEffect, useState } from 'react';
import type {PropsWithChildren} from 'react';
import Start from './Start';
import StartSub from './StartSub';
import AvailableLinks from './AvailableLinks';
import BottomSocial from '../footer/BottomSocial';
import BottomStats from '../footer/BottomsStats';
import { useSpring, animated } from '@react-spring/web'

import '../../App.css';

type tsProps = PropsWithChildren<{
  scrollOffset: number;
}>;

function StartOuter({scrollOffset}: tsProps) {

    const transitionData = useSpring({
        from: {opacity: 0}, to: {opacity: 1}, config: {duration: 1000}
    })

    return (
        <animated.div style={transitionData} className="StartOuter">
            <Start scrollOffset={scrollOffset} />
            <StartSub scrollOffset={scrollOffset} />
            <AvailableLinks />
            <BottomStats />
            <BottomSocial />
        </animated.div>
    );
}

export default StartOuter;
